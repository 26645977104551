import VueCompositionApi, {
  onMounted,
  ref,
  SetupContext,
} from '@vue/composition-api';

// App main
const main = async () => {
  const { default: svg4everybody } = await import(
    /* webpackMode: "lazy", webpackChunkName: "svg4everybody" */ 'svg4everybody'
  );
  const { default: smoothscroll } = await import(
    /* webpackMode: "lazy", webpackChunkName: "smoothscroll-polyfill" */ 'smoothscroll-polyfill'
  );
  // Async load the vue module
  const { default: Vue } = await import(
    /* webpackMode: "lazy", webpackChunkName: "vue" */ 'vue'
  );
  const { default: VueMq } = await import(
    /* webpackMode: "lazy", webpackChunkName: "mq" */ 'vue-mq'
  );
  await import(
    /* webpackMode: "lazy", webpackChunkName: "lazysizes" */ 'lazysizes'
  );
  const { default: Photoswipe } = await import(
    /* webpackMode: "lazy", webpackChunkName: "photoswipe" */ 'vue-pswipe'
  );
  const { default: TransitionExpand } = await import(
    /* webpackMode: "lazy", webpackChunkName: "transition-expand" */ './components/TransitionExpand.vue'
  );
  const { default: ProjectComponent } = await import(
    /* webpackMode: "lazy", webpackChunkName: "project-component" */ './components/ProjectComponent.vue'
  );
  const { default: toggleNavigation } = await import(
    /* webpackMode: "lazy", webpackChunkName: "toggle-navigation" */ './directives/toggleNavigation'
  );
  const { default: navigationAnimation } = await import(
    /* webpackMode: "lazy", webpackChunkName: "navigation-animation" */ './directives/navigationAnimation'
  );
  // const { default: SkeletonComponent } = await import(
  //   /* webpackMode: "lazy", webpackChunkName: "skeleton-component" */ './components/SkeletonComponent.vue'
  // );
  // const { default: lazyLoadComponent } = await import(
  //   /* webpackMode: "lazy", webpackChunkName: "lazy-load-component" */ '../utils/lazy-load-component'
  // );

  svg4everybody();
  smoothscroll.polyfill();

  Vue.config.productionTip = false;

  Vue.use(VueCompositionApi);
  Vue.use(Photoswipe, {
    bgOpacity: 0.9,
    closeOnScroll: false,
    closeOnVerticalDrag: false,
    fullscreenEl: false,
    shareEl: false,
    zoomEl: false,
    counterEl: false,
    barsSize: { top: 40, bottom: 40 },
  });
  Vue.use(VueMq, {
    breakpoints: {
      sm: 768,
      md: 1024,
      lg: Infinity,
    },
  });
  Vue.component('transition-expand', TransitionExpand);
  Vue.component('project-component', ProjectComponent);
  Vue.directive('toggle-navigation', toggleNavigation);
  Vue.directive('navigation-animation', navigationAnimation);

  // Create our vue instance
  const vm = new Vue({
    el: '#page-container',

    setup(props: any, context: SetupContext): any {
      const showNavigation = ref(false);
      onMounted(() => {
        context.root.$on('toggleNavigation', () => {
          showNavigation.value = !showNavigation.value;
          document.documentElement.classList.toggle('overflow-hidden');
        });

        if (window.location.hash.length) {
          const target: HTMLElement | null = document.querySelector(
            window.location.hash
          );

          if (target) {
            setTimeout(() => {
              document.documentElement.scrollBy({
                top: target.offsetTop - 40,
                behavior: 'smooth',
              });
            }, 500);
          }
        }

        const handleFirstScroll = (): void => {
          document.documentElement.classList.add('user-has-scrolled');
          window.removeEventListener('scroll', handleFirstScroll);
        };
        window.addEventListener('scroll', handleFirstScroll);
      });

      const pauseAllVideos = () => {
        const videos: HTMLVideoElement[] = Array.from(
          document.querySelectorAll('video:not([autoplay])')
        );
        videos.forEach(video => {
          video.pause();
          // eslint-disable-next-line no-param-reassign
          video.currentTime = 0;
        });
      };

      const autoplayIfVideo = (event: any) => {
        const videos: HTMLVideoElement[] = Array.from(
          event.container.querySelectorAll('video')
        );

        const videoInViewport = videos.find(
          video => video.getBoundingClientRect().width > 0
        );
        if (videoInViewport) {
          videoInViewport.play();
        }
      };

      return { showNavigation, pauseAllVideos, autoplayIfVideo };
    },
  });

  return vm;
};

// Execute async function
main().then(vm => {});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
